<script>

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      user: {
        name: ''
      }
    }
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    getUserData() {
      this.$store.dispatch('USER_DATA').then((res) => {
        this.user = res;
      }).catch(() => {
        // console.log(err)
      })
    },
    logout() {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/')
      })
    }
  },
  mounted() {
    this.getUserData();
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-avatar.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="30" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-avatar-white.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-white.png" alt height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <!-- <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown> -->

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
          v-if="$store.getters.orderStarted"
        >
          <template v-slot:button-content>
            <i class="bx bx-cart"></i>
            <span class="badge badge-primary-inverse badge-pill">{{$store.getters.cartQuantity}}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Orçamento em Andamento</h6>
              </div>
            </div>
          </div>
          <!-- <simplebar style="max-height: 230px;"> -->
          <router-link tag="a" to="/cart">
            <div class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Total: R$ {{$store.getters.cartValue | currency}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Qtd. Itens: {{$store.getters.cartQuantity || 0}}</p>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          <!-- </simplebar> -->

          <!-- <div class="p-2 border-top text-center">
            <a class="btn btn-light text-center mr-2" href="javascript:void(0)">
              Cancelar
            </a>

            <a class="btn btn-primary text-center ml-2" href="javascript:void(0)">
              Finalizar
            </a>
          </div> -->
        </b-dropdown>

        <!-- <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>

            <span class="d-none d-xl-inline-block ml-1">{{user.name.toUpperCase()}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block ml-2"></i>
          </template>
          <a class="dropdown-item text-danger" @click="logout">
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Sair
          </a>
        </b-dropdown> -->

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
          >
            {{user.name.toUpperCase()}}
          </button>
        </div>

        <div class="dropdown d-inline-block">
          <button
            @click="logout"
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
          >
            <!-- <i class="bx bx-exit"></i>SAIR -->
            SAIR
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
  .badge-primary-inverse {
    background-color: #ffffff;
    color: #1c1798
  }

  .dropdown-menu-lg {
    width: 250px;
  }
</style>